import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FacebookComponent } from "./buttom-facebook/facebook.component";
import { SignInCompanyComponent } from "./sign-in-company/sign-in-company.component";
import { FillFederatedInfoComponent } from "./fill-federated-info/fill-federated-info.component";

const routes: Routes = [
  {
    path: "company",
    component: SignInCompanyComponent,
    children: [
      {
        path: "customer",
        component: FacebookComponent,
      },
    ],
  },
  {
    path: "federated",
    loadChildren: () =>
      import("./fill-federated-info/fill-federated-info.module").then(
        (m) => m.FillFederatedInfoModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignInRoutingModule {}
