import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppResourcesService } from "@core/resources/app-resources.service";
import { FirebaseAuthService } from "@core/services/firebase-auth.service";
import { LocalStorageService } from "@core/services/local-storage.service";
import {
  CreateUserDto,
  OriginType,
  UserType,
} from "@domain/dto/create-user-dto";
import { GetUserParams } from "@domain/models/getUser-params";
import { User } from "@domain/models/user";
import { GetUserDataUsecase } from "@domain/usecases/get-user-data-usecase";
import { RegisterUserDataUsecase } from "@domain/usecases/register-user-data-usecase";
import { MetamapValidationStatus } from "@presentation/utils/custom-types/custom-types";
import { MetamapComponent } from "../../sign-up/components/metamap/metamap.component";

@Component({
  selector: "app-facebook",
  templateUrl: "./facebook.component.html",
  styleUrl: "./facebook.component.scss",
})
export class FacebookComponent {
  iconFacebook = AppResourcesService.icons.facebook;
  @Output()
  public verifyMetamapFacebook = new EventEmitter<User>();

  constructor(
    private authService: FirebaseAuthService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private getUserDataUseCase: GetUserDataUsecase
  ) {}

  loginFacebook() {
    this.authService
      .loginFacebook()
      .then(async (res) => {
        //We search if the user has already registered
        let params: GetUserParams = {
          uid: res.user.uid,
          token: await res.user.getIdToken(),
        };
        var result = await this.getUserDataUseCase.execute(params);
        // Assuming result.fold returns the user object
        const user = result.fold(
          (l) => null,
          (r) => r
        );
        //If user doesn't exist, register user data
        if (user == null) {
          this.authService.setUserCredential(res);
          this.router.navigate(["/login/federated"]);
        } else {
          this.localStorageService.setData("userData", user);
          this.verifyMetamapFacebook.emit(user);
        }
      })
      .catch((err) => console.log("Ocurrió un error: " + err));
  }
}
