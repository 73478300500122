import { PREFIX_TREEBU_HASURA, PREFIX_TREEBU_LEDGER, TREEBU_HAUSRA, TREEBU_LEDGET } from "../constants/environment.constant";


export class AppRoutesConfig {
  public static apiLedger(uri: string): string {
    return `${TREEBU_LEDGET}/${PREFIX_TREEBU_LEDGER}/${uri}`;
  }

  public static apiHasura(uri: string): string {
    return `${TREEBU_HAUSRA}/${PREFIX_TREEBU_HASURA}/${uri}`;
  }
}
