<div class="content" *ngIf="showForm">
  <div class="content-header-row">
    <span class="text-back"> <i class="pi pi-chevron-left"></i> Volver</span>
    <div class="title-container">
      <h1 class="content-title">
        ¡Bienvenido a <span class="empresa-title">Treebu!</span>
      </h1>
      <span class="content-subtitle"
        >Ingresa tus datos para iniciar sesión</span
      >
    </div>
  </div>
  <div class="form-container">
    <form [formGroup]="form" class="form">
      <div class="field">
        <app-textfield
          [label]="'Correo electrónico'"
          formControlName="email"
          [placeHolder]="'Ingresar'"
          [width]="'100%'"
          [height]="'51px'"
          [iconPath]="'assets/icons/correo_electronico_icon.svg'"
          [type]="'email'"
          (input)="onEmailFieldChange()"
        ></app-textfield>

        <div
          *ngIf="
            (form.get('email')?.invalid && form.get('email')?.dirty) ||
            emailExistsError
          "
          class="error-message"
        >
          <img src="\assets\icons\error.svg" alt="" />
          <span>{{
            emailExistsError
              ? "* Correo electrónico no ha sido registrado"
              : emailMsg
          }}</span>
        </div>
      </div>
      <div class="field">
        <app-textfield
          [label]="'Contraseña'"
          formControlName="password"
          [placeHolder]="'Ingresar'"
          [width]="'100%'"
          [height]="'51px'"
          [iconPath]="'assets/icons/password_icon.svg'"
          [validationConfig]="{ patternPass: true, required: true }"
          [type]="'password'"
        ></app-textfield>
        <div
          *ngIf="form.get('password')?.invalid && form.get('password')?.touched"
          class="error-message"
        >
          <span [innerHTML]="errorPass"></span>
        </div>
      </div>
      <span class="forgot-password" (click)="onLostPasswordTextClick()"
        >¿Olvidaste tu contraseña?</span
      >
      <div class="recaptcha">
        <ngx-recaptcha2
          #captchaElem
          [siteKey]="siteKey"
          formControlName="recaptcha"
        >
        </ngx-recaptcha2>
      </div>
    </form>
  </div>
  <div class="form-buttons">
    <div class="button-container">
      <app-button
        (buttonClicked)="onLoginButtonTap()"
        text="Iniciar sesión"
        width="100%"
        height="56px"
        [disabled]="!form.valid || form.pending || emailExistsError"
      ></app-button>
    </div>
  </div>
  <app-facebook *ngIf="showFacebookButton()" (verifyMetamapFacebook)="verifyMetamapFacebook($event)"></app-facebook>
  <footer>
    <div class="footer-content">
      <p class="footer-text">
        ¿Aún no tienes una cuenta? <span class="ingresar cursor-pointer" (click)="goToRegister()">Regístrate</span>
      </p>
    </div>
  </footer>
</div>
<app-metamap
  (onVerificationCompleted)="onMetamapVerificationCompleted()"
  (onVerificationCancelled)="onMetamapVerificationCancelled()"
></app-metamap>
<app-metamap-modal *ngIf="showInprocessModal"></app-metamap-modal>
<app-metamap-modal
  *ngIf="showNewAttempModal"
  [tittleText]="'Algo salió mal'"
  [contentText]="
    'Ocurrió un error durante el proceso de verificación. No te preocupes, aún tienes ' +
    metamapAttempts +
    ' intentos restantes.'
  "
  [modalIcon]="iconCancel"
  [buttonLeftText]="'Ir a Soporte'"
  [buttonRigthText]="'Volver a intentarlo'"
  (ButtonRightEvent)="isContinue()"
  [showButtons]="true"
></app-metamap-modal>
<app-metamap-modal
  *ngIf="isCancel"
  [tittleText]="'Algo salió mal'"
  [contentText]="'Ocurrió un error durante el proceso de verificación.'"
  [modalIcon]="iconCancel"
  [twoButtons]="false"
  [buttonRigthText]="'Volver a intentarlo'"
  (ButtonRightEvent)="isContinue()"
  [hrWidth]="'546px'"
  [showButtons]="true"
></app-metamap-modal>
<app-metamap-modal
  *ngIf="showNewFailModal"
  [tittleText]="'Validación fallida'"
  [contentText]="
    'Lo sentimos, has agotado los máximos intentos permitidos para realizar la validación de identidad. Comunícate con nuestro equipo de Soporte.'
  "
  [modalIcon]="iconErrorX"
  [showButtons]="true"
  [buttonLeftText]="'Ir a Soporte'"
  [buttonRigthText]="'Ir al inicio'"
  (ButtonRightEvent)="goToLogin()"
></app-metamap-modal>
