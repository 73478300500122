import { Injectable } from '@angular/core';
import { AppRoutesConfig } from './app-routes.config';

@Injectable({
  providedIn: 'root',
})
export class AppRoutesService {
  public static ledger = {
    signIn: AppRoutesConfig.apiHasura("sign-in"),
    createUser:AppRoutesConfig.apiLedger("User"),
    metamapAttempt:AppRoutesConfig.apiLedger("User/metamap-attempt")
  };

}
