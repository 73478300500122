import { Component, OnInit, ViewChild, inject } from "@angular/core";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SignInData } from "@domain/models/sign-in-data";
import { User } from "@domain/models/user";
import { GetUserDataUsecase } from "@domain/usecases/get-user-data-usecase";
import { SignInWithEmailAndPasswordUsecase } from "@domain/usecases/sign-in-with-email-and-password-usecase";
import { ToastsService } from "@presentation/utils/molecules/toasts/toasts.service";
import { MetamapValidationStatus } from "../../../../../utils/custom-types/custom-types";
import { MetamapComponent } from "../../sign-up/components/metamap/metamap.component";
import { Observable, firstValueFrom, from, map } from "rxjs";
import { FirebaseAuthService } from "@core/services/firebase-auth.service";
import { AppResourcesService } from "@core/resources/app-resources.service";
import { environment } from "@environments/environment.development";
import { VerifyEmailExistanceUsecase } from "@domain/usecases/verify-email-existance-usecase";
import { LocalStorageService } from "@core/services/local-storage.service";
import { AppRoutesService } from "@core/routes/app-routes.service";
import { RequestService } from "@core/http/request.service";
import { User as FirebaseUser } from "firebase/auth";
import { Failure } from "@core/helpers/failure";
import { GetUserParams } from "@domain/models/getUser-params";
import { UserType } from "@domain/dto/create-user-dto";
import { user } from "@angular/fire/auth";

@Component({
  selector: "app-sign-in-company",
  templateUrl: "./sign-in-company.component.html",
  styleUrl: "./sign-in-company.component.scss",
})
export class SignInCompanyComponent implements OnInit {
  @ViewChild(MetamapComponent) metamapComponent: MetamapComponent;
  _firebaseAuthService = inject(FirebaseAuthService);
  _requestService = inject(RequestService);
  public form: FormGroup;
  showInprocessModal: boolean = false;
  showNewAttempModal: boolean = false;
  emailExistsError: boolean = false;
  showNewFailModal: boolean = false;
  showForm: boolean = true;
  iconCancel = AppResourcesService.icons.error;
  iconErrorX = AppResourcesService.icons.error_x;
  siteKey: string = environment.siteKey;
  firebaseUid: string;
  isCancel: boolean = false;
  metamapAttempts: number = 0;

  errorPass: string =
    '<div class="d-flex ">' +
    '<img class="position-relative mb-3" src="/assets/icons/warning.svg" alt="" />' +
    "<ul>" +
    "<li>Incluir letras, números y caracteres especiales.</li>" +
    "<li>Combinar letras mayúsculas y minúsculas.</li>" +
    "<li>En longitud debe ser igual o mayor a 8 caracteres.</li>" +
    "<li>No debe tener espacios en blanco.</li>" +
    "</ul></div>";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private getUserDataUseCase: GetUserDataUsecase,
    private signInWithEmailAndPasswordUsecase: SignInWithEmailAndPasswordUsecase,
    private verifyEmailExistanceUsecase: VerifyEmailExistanceUsecase,
    private toastsService: ToastsService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
          ),
        ],
      ],
      recaptcha: ["", Validators.required],
    });
  }

  async onLoginButtonTap() {
    this.toastsService.showLoadingToast();

    let email = this.form.get("email")?.value;

    let emailRegistered = await this.verifyEmailExistance(email);
    if (emailRegistered == null) {
      this.toastsService.hideLoadingToast();
      this.toastsService.showInfoToast(
        "No se pudo comprobar el usuario. Intenta de nuevo"
      );
      return;
    }

    if (!emailRegistered) {
      this.toastsService.hideLoadingToast();
      this.emailExistsError = true;
      return;
    }

    let userFirebase = await this.signInWithEmailAndPassword({
      email: email,
      password: this.form.get("password")?.value,
    });

    if (userFirebase instanceof Failure) {
      this.toastsService.hideLoadingToast();
      this.toastsService.showInfoToast("No se pudo iniciar sesión");
      return;
    } else {
      if (userFirebase.uid == "wrong-password") {
        this.toastsService.hideLoadingToast();
        this.toastsService.showInfoToast("Contraseña incorrecta");
        return;
      }

      let params: GetUserParams = {
        uid: userFirebase.uid,
        token: await userFirebase.getIdToken(),
      };

      let userData = await this.getUserData(params);
      this.toastsService.hideLoadingToast();
      if (userData == null) {
        this.toastsService.showInfoToast(
          "No se pudo obtener la info del usuario"
        );
        return;
      }

      if (userData.userType == UserType.delegate) {
        if (userData.companyValidationStatus == 1) {
          this.toastsService.hideLoadingToast();
          this.toastsService.showInfoToast(
            "La empresa se encuentra en verificación \n Comunícate con un asesor de Treebu para que te brinde más información."
          );
          return;
        }
        if (userData.companyValidationStatus == 3) {
          this.toastsService.hideLoadingToast();
          this.toastsService.showInfoToast(
            "La empresa fue rechazada \n Comunícate con un asesor de Treebu para que te brinde más información."
          );
          return;
        }
        if (userData.metamapOnboarding == true) {
          this.router.navigate(["/admin/dashboard"]);
        }
        this.showInprocessModal = true;
        this.showForm = false;
        return;
      }

      this.metamapValidationFlow(userData);
    }
  }

  async signInWithEmailAndPassword(
    signInData: SignInData
  ): Promise<FirebaseUser | Failure> {
    let result = await this.signInWithEmailAndPasswordUsecase.execute(
      signInData
    );
    return result.fold<FirebaseUser | Failure>(
      (failure) => {
        return failure;
      },
      (r) => r
    );
  }

  async getUserData(params: GetUserParams): Promise<User | null> {
    var result = await this.getUserDataUseCase.execute(params);
    return result.fold<User | null>(
      (l) => {
        return null;
      },
      (r) => {
        if (
          (r.userType == "DELEGADO" ||
            r.userType == "REPRESENTANTE" ||
            r.userType == "PERSONA_JURIDICA") &&
          this.router.url == "/login/company/customer"
        ) {
          return null;
        } else if (
          r.userType == "PERSONA_NATURAL" &&
          this.router.url == "/login/company"
        ) {
          return null;
        }
        this.localStorageService.setData("userData", r);

        if (r.metamapAttempts == 3) {
          this.showForm = false;
          this.showNewFailModal = true;
        }
        return r;
      }
    );
  }

  metamapValidationFlow(userData: User) {
    let metamapValidationStatus: MetamapValidationStatus =
      this.getMetamapValidationStatus(userData);
    if (metamapValidationStatus == MetamapValidationStatus.approved) {
      //We check the company status before letting the user log in
      if (userData.userType == "REPRESENTANTE") {
        if (userData.companyValidationStatus == 1) {
          this.toastsService.hideLoadingToast();
          this.toastsService.showInfoToast(
            "La empresa se encuentra en verificación \n Comunícate con un asesor de Treebu para que te brinde más información."
          );
          return;
        }
        if (userData.companyValidationStatus == 3) {
          this.toastsService.hideLoadingToast();
          this.toastsService.showInfoToast(
            "La empresa fue rechazada \n Comunícate con un asesor de Treebu para que te brinde más información."
          );
          return;
        }
      }
      this.router.navigateByUrl("/admin/dashboard");
      return;
    }

    if (metamapValidationStatus == MetamapValidationStatus.inProgress) {
      this.showInprocessModal = true;
      this.showForm = false;
      return;
    }

    if (metamapValidationStatus == MetamapValidationStatus.available) {
      if (userData.metamapAttempts > 0) {
        this.firebaseUid = userData.uid;
        this.metamapAttempts = userData.metamapAttempts;
        this.showNewAttempModal = true;
        this.showForm = false;
        return;
      }
      this.showForm = false;
      this.metamapComponent!.startMetamapVerification(userData.uid);
      return;
    }

    if (metamapValidationStatus == MetamapValidationStatus.rejected) {
      this.showNewFailModal = true;
      this.showForm = false;
      return;
    }
  }

  getMetamapValidationStatus(userData: User): MetamapValidationStatus {
    if (userData.metamapOnboarding) {
      return MetamapValidationStatus.approved;
    }

    if (userData.metamapValidationInProgress) {
      return MetamapValidationStatus.inProgress;
    }

    if (userData.metamapAttempts < 3) {
      return MetamapValidationStatus.available;
    }

    return MetamapValidationStatus.rejected;
  }

  async verifyEmailExistance(email: string): Promise<boolean | null> {
    let result = await this.verifyEmailExistanceUsecase.execute(email);
    return result.fold(
      (l) => null,
      (r) => r
    );
  }

  emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return from(this._firebaseAuthService.emailExists(control.value)).pipe(
        map((eitherValue) => {
          return eitherValue.fold(
            (left) => {
              console.error("Error or failure:", left);
              return null; // Custom error for failure
            },
            (right) => {
              if (right) {
                return null; // Return custom error if email exists
              } else {
                return { emailExistsError: true }; // Return null if email does not exist
              }
            }
          );
        })
      );
    };
  }

  onMetamapVerificationCompleted() {
    this.saveAttempt();
    this.showInprocessModal = true;
  }

  isContinue() {
    this.isCancel = false;
    this.showNewAttempModal = false;
    this.metamapComponent!.startMetamapVerification(this.firebaseUid);
  }

  async saveAttempt(): Promise<void> {
    let body = {
      firebaseUserId: this.firebaseUid,
    };
    let response = this._requestService.post(
      AppRoutesService.ledger.metamapAttempt,
      body
    );
    await firstValueFrom(response);
  }

  onMetamapVerificationCancelled(): void {
    this.isCancel = true;
  }

  onLostPasswordTextClick() {
    this.router.navigate(["/password-reset"]);
  }

  onEmailFieldChange() {
    if (this.emailExistsError == false) return;
    this.emailExistsError = false;
  }

  // Mensajes de validacion segun el tipo de validator
  get emailMsg(): string {
    const form: FormControl = this.form.get("email") as FormControl;
    return form.hasError("required")
      ? "Campo obligatorio"
      : form.hasError("pattern")
      ? "Debe ingresar un email válido"
      : form.hasError("emailExistsError")
      ? "* Correo electrónico no ha sido registrado"
      : "";
  }

  goToRegister() {
    if (this.router.url == "/login/company/customer") {
      this.router.navigate(["/register/register-consumer"]);
    } else if (this.router.url == "/login/company") {
      this.router.navigate(["/register/register-option"]);
    }
  }

  goToLogin() {
    if (this.router.url == "/login/company/customer") {
      this.router.navigate(["/login/company/customer"]);
    } else if (this.router.url == "/login/company") {
      this.router.navigate(["/login/company"]);
    }
  }

  showFacebookButton(): boolean {
    if (this.router.url == "/login/company/customer") return true;
    return false;
  }

  verifyMetamapFacebook(user: User) {
    if (user.metamapAttempts == 3) {
      this.showForm = false;
      this.showNewFailModal = true;
    }
    this.metamapValidationFlow(user);
  }
}
