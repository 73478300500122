import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { UtilsModule } from "../../../../utils/utils.module";
import { SignInCompanyComponent } from "./sign-in-company/sign-in-company.component";
import { SignInRoutingModule } from "./sign-in-routing.module";
import { MetamapComponent } from "../sign-up/components/metamap/metamap.component";
import { FillFederatedInfoModule } from "./fill-federated-info/fill-federated-info.module";
import { FacebookComponent } from "./buttom-facebook/facebook.component";
import { FillFederatedInfoComponent } from "./fill-federated-info/fill-federated-info.component";

@NgModule({
  declarations: [SignInCompanyComponent, FacebookComponent],
  exports: [SignInCompanyComponent, FacebookComponent],
  imports: [
    CommonModule,
    SignInRoutingModule,
    UtilsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MetamapComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SignInModule {}
