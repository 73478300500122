<div class="container-principal mt-4">
  <div class="container-inside mb-4">
    <hr />
    <span>O entrar con</span>
    <hr />
  </div>
  <div class="btn-facebook cursor-pointer" (click)="loginFacebook()">
    <a class="list-group-item bg-transparent p-2 border-0">
      <div class="icon-img">
        <img [src]="iconFacebook" />
      </div>
      <span>Entrar con Facebook</span>
    </a>
  </div>
</div>
