import { Injectable } from "@angular/core";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { UseCase } from "../../core/helpers/use-case";
import { SignInData } from "@domain/models/sign-in-data";
import { AuthRepository } from "@domain/repository/auth-repository";
import { User } from "firebase/auth";

@Injectable({
    providedIn: 'root'
})
export class SignInWithEmailAndPasswordUsecase implements UseCase<User, SignInData>{
    constructor(private authRepository: AuthRepository) { }

    async execute(params: SignInData): Promise<Either<Failure, User>> {
        return await this.authRepository.signInWithEmailAndPassword(params);
    }
}